<template>
  <v-layout class="loginPage" fill-height align-center justify-center>
    <div class="loginContainer">
      <img :src="require(`@/assets/images/logo${pineDx}.svg`)" :alt="`Pine-DX${pineDx}`">
      <div class="loginInput">
        <label><input type="text" placeholder="ID" v-model="loginId" @keyup.enter="login()" ref="loginIdRef"><i class="micon">person</i></label>
        <label><input type="password" placeholder="Password" v-model="loginPw" @keyup.enter="login()" ref="loginPwRef"><i class="micon">lock</i></label>
        <a class="btn loginBtn" @click="login()">로그인</a>
      </div>
      <button class="solutionButton" @click="openInfoPopup()"><span><i class="micon">info</i>솔루션 정보</span></button>
    </div>
    <Loading fullscreen="true" size="32" :text="messages" :opacity="0.5" :isShow="isLoading"/>
    <!-- 솔루션 정보 팝업 -->
    <v-dialog v-model="infoPopupVisible">
        <InfoPopup @closeInfoPopup="closeInfoPopup" />
    </v-dialog>
  </v-layout>
</template>

<script>
import API_LOGIN from '../../API/user/login';

export default {
  middleware: "guest",
  components: {
      InfoPopup: () => import('../../popup/infoPopup.vue'),
    },
  data() {
    return {
      loginId:'',
      loginPw:'',
      isLoading: false,
      messages: '로그인중..',
      pineDx: process.env.VUE_APP_PINE_DX,
      infoPopupVisible: false
    };
  },
  mounted() {
    this.initLocalStorage();
  },
  methods: {
    async login(){
      if(this.isLoading) return;
      if(this.loginId==''){
        this.showPopup('아이디를 입력해주세요');
        return;
      }
      if(this.loginPw==''){
        this.showPopup('비밀번호를 입력해주세요');
        return;
      }
      this.isLoading = true;
      const startTime = Date.now();
      let res = await API_LOGIN.request(
          this.loginId,
          this.loginPw
      )
      const endTime = Date.now();
      if(endTime - startTime < 500)
        await new Promise(resolve => setTimeout(resolve, 500));
        this.isLoading = false;
      if (res.isSuccess) {
        await this.$store.dispatch('auth/fetchData');
        const userRole = this.$store.getters['auth/userRole']
        if(userRole === "A") this.$router.push({ name: 'userManage' });
        else this.$router.push({ name: 'step1' });
      } else {
        this.showPopup(res.errorMsg);
      }
    },
    openInfoPopup() {
      this.infoPopupVisible = true;    
    },
    closeInfoPopup() {
      this.infoPopupVisible = false;
    },
  }
};
</script>

<style lang="scss" scoped>

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.input-container {
  width: 400px;
  padding: 50px 20px;
}

.login-input {
  border: 2px solid rgba(223, 223, 223, 0.4);
  border-radius: 22px;
  margin-bottom: 12px;
}

.login-button {
  border-radius: 22px;
  margin-top: 24px;
  padding: 26px !important;
  font-size: 16px;
  height: auto;
  line-height: 1;
  color: #DFDFDF;
  font-weight: 600;
  border: 1px solid rgba(223, 223, 223, 0.3) !important;
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  input{
    color: rgba(255, 255, 255, 0.8);
  }
  i{
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
  }
  label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
}

::v-deep .v-text-field__slot {
  margin-left: 10px;
}

.solutionButton{color: rgba(255,255,255,0.8); margin-top: 25px; min-height: 24px; border-bottom: 1px solid transparent; transition: color .3s; }
.solutionButton:hover{color: #fff;}
.solutionButton span{display: flex; align-items: center;}
.solutionButton span i{margin-right: 5px;}

</style>